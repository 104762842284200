import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './Loading.module.scss';

interface LoadingProps {
  additionalClass?: string;
  shouldInheritColor?: boolean;
}

const Loading: FC<LoadingProps> = ({
  additionalClass = styles.submitLoader,
}): JSX.Element => (
  <div className={clsx(styles.loading, additionalClass)}>
    <CircularProgress color="inherit" />
  </div>
);

export default Loading;
