import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';


import './index.scss';

import './i18n';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './ui/Loading/Loading';

const App = lazy(() => import('./App'));

import('@fullstory/browser').then((module) => {
  module.init({ orgId: `${process.env.REACT_APP_FULLSTORY_ORG_ID}` });
});

import('./serviceWorker').then((module) => {
  module.register();
});

(async (): Promise<void> => {
  const div = document.getElementById('root');
  const root = createRoot(div!);

  root.render(
    <Suspense fallback={<Loading />}>
      < App />
    </Suspense >
  )
})();
